<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form @submit="saveMarkSubmissionRecord" method="post" action="javascript:void(0)" ref="form_mark_upload"
        class="repeater-form">
        <b-row>
          <b-col md="3">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Exam type") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('exam type')" rules="required">
                <select v-model.number="exam_type_id" class="form-control" name="exam_type_id" id="exam_type_id"
                  @change="isMarkAlreadySumitted()" :state="errors.length > 0 ? false : null">
                  <option :value="null">Select Exam Type</option>
                  <option v-for="(eType, key) in exmTypes" :key="key" :value="eType.id">
                    {{ eType.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Wings") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('wings')" rules="required">
                <select v-model.number="wing_id" class="form-control" name="wing_id" id="wing_id"
                  @change="isMarkAlreadySumitted()" :state="errors.length > 0 ? false : null">
                  <option :value="null">Select Institution</option>
                  <option v-for="(wing, key) in wings" :key="key" :value="wing.id">
                    {{ wing.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Groups") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('wings')" rules="required">
                <select v-model.number="student_group_id" class="form-control" name="student_group_id"
                  id="student_group_id" @change="loadUploadedMark(); isMarkAlreadySumitted(); "
                  :state="errors.length > 0 ? false : null">
                  <option :value="null">Select Group</option>
                  <option v-for="(group, key) in student_groups" :key="key" :value="group.id">
                    {{ group.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Subject") }} </span>
              </b-card-text>
              <validation-provider #default="{ errors }" :name="$t('subject')" rules="">
                <select id="subject_id" class="form-control" disabled :state="errors.length > 0 ? false : null">
                  <option :value="subject">
                    {{ subjectByID(subject) }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <template v-if="exam_type_id">
          <!-- Header of tabular representation of the student's information-->
          <b-row>
            <template>
              <b-col md="2">
                <b-card-text>
                  <span>{{ $t("Student No") }} </span>
                </b-card-text>
              </b-col>
              <!-- Mark from -->
              <b-col md="2">
                <b-card-text>
                  <span>{{ $t("Student Name") }} </span>
                </b-card-text>
              </b-col>
              <b-col md="2" v-for="(mark_distribution, index) in mkDistributions" :key="index">
                <template>
                  {{ mark_distribution.name }} ({{ (mark_distribution.total_mark) }})
                </template>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </template>
          </b-row>

          <!-- Row Loop -->
          <b-row v-for="(item, index) in studentList" :id="item.id" :key="index" ref="row">
            <template v-if="isSubjectTaken(item, mkDistributions)">
              <b-col md="2">
                <b-form-group>
                  <validation-provider #default="{ errors }" :name="$t('student_id')" rules="required">
                    <b-form-input :value="item.userable.cadet_no" readonly :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Student No')" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group>
                  <validation-provider #default="{ errors }" :name="$t('student_name')" rules="required">
                    <b-form-input :value="item.userable.name" readonly :state="errors.length > 0 ? false : null"
                      :placeholder="$t('student name')" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2" v-for="(mark_distribution, index) in mkDistributions" :key="index" ref="row2">
                <template>
                  <b-form-group>
                    <validation-provider #default="{ errors }" :name="$t('mark')" rules="required">
                      <b-form-input
                        @change="onObtainedMarkChanged(index, $event); saveChangedMark($event, item, mark_distribution.id)"
                        :id="mark_distribution.id + '-' + Math.floor(Date.now())"
                        :value="getUploadedMark(mark_distribution, item)" type="number"
                        :state="errors.length > 0 ? false : null" :placeholder="$t('mark')" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
              </b-col>

              <b-col cols="12">
                <hr />
              </b-col>
            </template>
          </b-row>

        </template>
        <b-row ref="row2" id="button_row">
          <template>
            <b-col md="2" class="mt-2 pt-1 col text-right">
              <b-button id="btn_save" value="save" variant="primary" type="submit">
                {{ $t('Save') }}
              </b-button>
            </b-col>
            <b-col md="2" class="mt-2 pt-1 col text-center">
              <b-button id="btn_submit" value="submit" variant="primary" type="submit">
                {{ $t('Submit') }}
              </b-button>
            </b-col>
            <b-col md="2" class="mt-2 pt-1 col text-left">
              <b-button id="btn_print" value="submit" variant="primary" type="submit">
                {{ $t('Print') }}
              </b-button>
            </b-col>
          </template>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BCardText,
} from "bootstrap-vue";
import { required } from "@validations";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import apiCall from "@/libs/axios";
//import constants from "@core/utils/constants";
import download from "downloadjs";

export default {
  name: "StudentMarkUpload",
  components: {
    BCardCode,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BRow,
    BCol,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    classes: { required: true },
    version: { required: true },
    shift: { required: true },
    section: { required: true },
    academic_year: { required: true },
    subject: { required: true },
  },
  data() {
    return {
      moment,
      required,
      exam_type_id: null,
      wing_id: null,
      student_group_id: null,
      obtained_mark: [],
      uploaded_mark_list: []
    };
  },
  methods: {
    loadUploadedMark() {
      let mark_collector = new FormData();
      mark_collector.append('classes_id', this.classes);
      mark_collector.append('exam_type_id', this.exam_type_id);
      mark_collector.append('academic_year_id', this.academic_year);

      apiCall.get('/employee/uploaded/filtered/marks', {
        params: {
          classes_id: this.classes,
          exam_type_id: this.exam_type_id,
          academic_year_id: this.academic_year
        }
      })
        .then(response => {

          this.uploaded_mark_list = response.data;
        })
    },
    onObtainedMarkChanged(index, $event) {
      this.mkDistributions[index].mark = $event;
    },
    saveChangedMark($event, student, mark_distribution_id) {
      let mark_info = new FormData();
      mark_info.append('subject_id', document.getElementById("subject_id").value);
      mark_info.append('exam_type_id', this.exam_type_id);

      mark_info.append('classes_id', this.classes);
      mark_info.append('student_id', student.id);
      mark_info.append('academic_year_id', this.academic_year);
      mark_info.append('mark_distribution_id', mark_distribution_id);
      mark_info.append('obtained_mark', $event);

      apiCall.post("/employee/student/mark/upload/changed/mark", mark_info)
        .then(() => {
          // console.log(response.data.message);
        })
        .catch(() => {
          // console.log(error.response.data.message);
        });
    },
   
    getUploadedMarkProperties() {
      let mark_info = new FormData();
      mark_info.append('subject_id', document.getElementById("subject_id").value);
      mark_info.append('exam_type_id', this.exam_type_id);
      mark_info.append('classes_id', this.classes);
      mark_info.append('academic_year_id', this.academic_year);
      mark_info.append('wing_id', document.getElementById("wing_id").value);
      mark_info.append('student_group_id', document.getElementById("student_group_id").value);

      return mark_info;
    },
    saveMarkSubmissionRecord() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let button_id = document.activeElement.getAttribute("id");
          if (button_id.toString() === 'btn_save')  // Making a draft for further modifcation.
          {
            let mark_info = new FormData();
            mark_info = this.getUploadedMarkProperties();
            mark_info.append('mark_submission_status', 1); // 1 = DRAFT, 2= SUBMITTED

            apiCall.post("/employee/student/mark/upload/submission", mark_info)
              .then((response) => {
                if (response.data.status === "success") {
                  this.$toaster.success(response.data.message);
                  this.$store.dispatch("GET_ALL_UPLOADED_DRAFT_MARK");
                } else this.$toaster.error(response.data.message);
              })
              .catch((error) => {
                if (error.response.status == 422) {
                  Object.keys(error.response.data.errors).map((field) => {
                    this.$toaster.error(error.response.data.errors[field][0]);
                  });
                } else this.$toaster.error(error.response.data.message);
              });
          }
          else if (button_id.toString() === 'btn_submit') {
            this.$swal({
              title: 'Are you sure?',
              text: "You would like to submit the marks. After submission, you can't revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Yes, submit it!',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.value) {
                let mark_info = new FormData();
                mark_info = this.getUploadedMarkProperties();
                mark_info.append('mark_submission_status', 2); // 1 = DRAFT, 2= SUBMITTED

                apiCall.post("/employee/student/mark/upload/submission", mark_info)
                  .then((response) => {
                    if (response.data.status === "success") {
                      this.$toaster.success(response.data.message);
                      this.$store.dispatch("GET_ALL_EMPLOYEE_UPLOADED_MARK");
                      this.$store.dispatch("GET_EMPLOYEE_SUBMITTED_MARK_LIST");
                      this.isMarkAlreadySumitted();
                    } else this.$toaster.error(response.data.message);
                  })
                  .catch((error) => {
                    if (error.response.status == 422) {
                      Object.keys(error.response.data.errors).map((field) => {
                        this.$toaster.error(error.response.data.errors[field][0]);
                      });
                    } else this.$toaster.error(error.response.data.message);
                  });
              }
            })
          }
          else if (button_id.toString() === 'btn_print') {
            let mark_info = new FormData();
            mark_info = this.getUploadedMarkProperties();
            apiCall
              .post("/employee/student/uploaded/mark/print", mark_info, {
                responseType: "blob",
              })
              .then((response) => {
                const content = response.headers["content-type"];
                download(response.data, "uploaded_mark_list.pdf", content);
              })
              .catch((error) => {
                this.$toaster.error(error.response.data.message);
              });
          }
        }
      })
    },
    getUploadedMark(mark, student) {
      let obtained_mark = 0;
      let uploaded_mark = this.uploaded_mark_list.find(
        item =>
          item.student_id == parseInt(student.id) &&
          item.mark_distribution_id == parseInt(mark.id)
      );

      if (uploaded_mark != undefined) {
        obtained_mark = uploaded_mark.total_mark;
      }
      else {
        obtained_mark = 0;
      }
      return obtained_mark;
    },

    isMarkAlreadySumitted() {

      let submitted_mark = this.employee_submitted_mark_list.find(
        (item) =>
          item.exam_type_id == this.exam_type_id &&
          item.subject_id == this.subject &&
          item.classes_id == this.classes &&
          item.wing_id == this.wing_id &&
          item.academic_year_id == this.academic_year &&
          item.mark_submission_status == 2
      );


      if (document.getElementById('button_row') != null && submitted_mark != null) {

        if (submitted_mark.has_extended_date == 0) {
          document.getElementById('button_row').style.visibility = 'hidden';
        }
        else if (submitted_mark.has_extended_date == 1 &&
          (submitted_mark.special_permission_start != null &&
            moment(submitted_mark.special_permission_start).format('YMMDD') < moment().format('YMMDD')) &&
          (submitted_mark.special_permission_end != null &&
            moment(submitted_mark.special_permission_end).format('YMMDD') < moment().format('YMMDD'))) {

          document.getElementById('button_row').style.visibility = 'visible';
        }
        else {
          document.getElementById('button_row').style.visibility = 'visible';
        }

        document.getElementById('btn_print').style.visibility = 'visible';
      }
      else {
        const element = document.getElementById('button_row');
        if (element != null) {
          element.style.visibility = 'visible';
        }
        //document.getElementById('button_row').style.visibility = 'visible';
      }
    },

    isSubjectTaken(item, markDistribution) {
      let isTaken = false;
      markDistribution.forEach((element) => {
        if (
          item.compulsory_subjects.includes(element.subject_id) ||
          item.optional_subjects.includes(element.subject_id)
        ) {
          isTaken = true;
        }
      });

      return isTaken;
    },
  },
  computed: {
    // direction() {
    //   if (store.state.appConfig.isRTL) {
    //     this.dir = true;
    //     return this.dir;
    //   }
    //   this.dir = false;
    //   return this.dir;
    // },
    /*Getting target students whose number will be uploaded.*/
    studentList() {
      const students = this.$store.getters.active_students.filter((item) => {
        if (
          item.classes_id == parseInt(this.classes) &&
          item.wing_id === parseInt(this.wing_id) &&
          item.student_group_id === parseInt(this.student_group_id) &&
          item.academic_year_id === parseInt(this.academic_year)
        )
          return item;
      });

      return students;
    },
    /*get target exam types*/
    exmTypes() {
      return this.$store.getters.active_exam_types;
    },
    /*get target mark distributions*/
    mkDistributions() {
      var filtered_md = this.$store.getters.mark_distributions.filter((item) => {
        if (
          item.exam_type_id == parseInt(this.exam_type_id) &&
          item.classes_id == parseInt(this.classes) &&
          item.academic_year_id === parseInt(this.academic_year) &&
          item.subject_id == parseInt(this.subject)
        )
          return item;
      });
      return filtered_md;
    },
    ...mapGetters([
      "active_students",
      "studentIdByID",
      "studentNameByID",
      "active_exam_types",
      "cadetIdByID",
      "mark_distributions",
      "student_mark_uploads",
      'employee_submitted_mark_list',
      "subjects",
      "subjectByID",
      'wings',
      'student_groups'
    ]),
  },
  created() {
    if (this.active_students.length < 1) this.$store.dispatch("GET_ALL_ACTIVE_STUDENT");

    if (this.subjects.length < 1) this.$store.dispatch("GET_ALL_SUBJECT");
    if (this.active_exam_types.length < 1) this.$store.dispatch("GET_ACTIVE_EXAM_TYPE");
    if (this.wings.length < 1) this.$store.dispatch('GET_ALL_WING');
    if (this.student_groups.length < 1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if (this.employee_submitted_mark_list.length < 1) this.$store.dispatch('GET_EMPLOYEE_SUBMITTED_MARK_LIST');
    
    if (this.mark_distributions.length < 1)
      this.$store.dispatch("GET_ALL_MARK_DISTRIBUTION");

    this.$store.dispatch("GET_EMPLOYEE_SUBMITTED_MARK_LIST");
    this.isMarkAlreadySumitted();
  },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
